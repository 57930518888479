<template>
  <div>
    <div id="pageLogin" class="mainContents">
      <section class="titleArea">
        <h2>ログイン</h2>
        <!-- /.titleArea -->
      </section>

      <form>
        <div class="loginArea">
          <div class="inner">
            <p v-if="hasError" class="errorMssage">
              入力されたメールアドレスとパスワードはアカウントと一致しません
            </p>

            <dl>
              <div>
                <dt>メールアドレス</dt>
                <dd class="inputWrap" :class="{ error: !validUsername }">
                  <input
                    v-model="username"
                    type="email"
                    placeholder="time@mail.com"
                  >
                </dd>
              </div>
              <div>
                <dt>パスワード</dt>
                <dd class="inputWrap" :class="{ error: !validPassword }">
                  <input
                    v-model="password"
                    type="password"
                    placeholder="＊＊＊＊＊＊＊＊"
                  >
                </dd>
              </div>
            </dl>
            <div class="buttonArea">
              <p>
                <button
                  type="submit"
                  :disabled="formInvalid"
                  @click="login($event)"
                >
                  ログイン
                </button>
              </p>
              <!-- /.buttonArea -->
            </div>
            <p class="reminder">
              <router-link :to="{ name: 'reminder' }">
                パスワードを忘れた方
              </router-link>
            </p>
          </div>
          <!-- /.loginArea -->
        </div>
      </form>

      <!-- /.mainContents -->
    </div>
  </div>
</template>

<script>
import { fieldNotNull, validEmail } from '@/helpers/validator.js'

export default {
  data () {
    return {
      isUploading: false,
      username: '',
      password: '',
      remember: '',
      error: false
    }
  },
  computed: {
    hasError: function () {
      if (this.error) {
        return true
      }
      return false
    },
    formInvalid: function () {
      if (this.isUploading) {
        return true
      }

      if (!this.validUsername) {
        return true
      }

      if (!this.validPassword) {
        return true
      }

      return false
    },
    validUsername: function () {
      return fieldNotNull(this.username) && validEmail(this.username)
    },
    validPassword: function () {
      return fieldNotNull(this.password)
    }
  },
  methods: {
    login (event) {
      event.preventDefault()
      this.isUploading = true
      this.error = false

      this.$store
        .dispatch('login', {
          username: this.username,
          password: this.password,
          remember: this.remember
        })
        .then(() => {
          this.isUploading = false
          this.$store.dispatch('getUnreadNewsCount')
          this.$store.dispatch('getUnreadMessageCount')

          this.$router.push({ name: 'home' })
        })
        .catch(() => {
          this.isUploading = false
          this.error = true
        })
    }
  }
}
</script>

<style scoped></style>
